@import 'bootstrap.min.css';

/* Sticky footer styles
-------------------------------------------------- */

body {
  /* Margin bottom by footer height */
  padding-bottom: 3rem;
  background-color: #333;
  color: #fff;
}

.footer {
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  line-height: 1.5rem;
  letter-spacing: 0.07rem;
  background-color: #333;
  font-size: 85%;
}

.article .date {
  font-style: italic;
  font-size: 90%;
}

.frontpage-date {
  font-style: italic;
  font-size: 90%;
}

.article p:first-of-type {
  width: 65%;
  font-size: 120%;
  line-height: 1.6rem;
  letter-spacing: 0.02rem;
  padding-bottom: 20px;
  padding-top: 10px;
}

.article p {
  line-height: 1.5rem;
  letter-spacing: 0.02rem;
  margin-bottom: 18px;
}

h2,
h3 {
  letter-spacing: 0.02em;
}

h4.detail {
  font-size: 115%;
}

th {
  letter-spacing: 0.08rem;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
  border-bottom: 0;
}

.backdrop-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.backdrop-overlay img {
  margin: 0;
  width: 100%;
  opacity: 0.3;
}

.front-text {
  min-width: 20rem;
}

.front-image {
  border: 1px solid #000;
  border-radius: 2px;
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.rider-img {
  border: 1px solid #000;
  border-radius: 2px;
  width: 100%;
  border-radius: 5px;
  border: 2px solid #000;
}

.rider-info {
  font-size: 120%;
}

.space {
  height: 60px;
}

.float {
  float: right;
}

.float-left {
  float: left;
}

.navbar .nav-link.active {
  color: #fff;
  border-bottom-color: #fff;
}

.navbar .nav-link {
  padding: 0.6rem 0 0.25rem 0;
  font-weight: 500;
  border-bottom: 0.2rem solid transparent;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .nav-link:hover,
.navbar-dark .nav-link:focus,
.navbar .nav-link:hover,
.navbar .nav-link:focus {
  border-bottom-color: #fff;
  color: #fff;
}

margin-bottom-large {
  margin-bottom: 15px;
}

.graph-container {
  padding-top: 25px;
}

a.none,
a.none:focus,
a.none:hover {
  border-bottom: 0;
}

a {
  color: #fff;
  text-decoration: none;
  padding-bottom: 0.4px;
}

main a[href] {
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid #aaa;
}

header a {
  border-bottom: 0;
}

a:focus,
a:hover {
  color: #fff;
  text-decoration: none;
  border-bottom: 1px solid white;
}

a.toplink {
  border-bottom: 0 !important;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.race-details,
.series-details {
  transition: all 0.3s ease;
}

.plus-rotate {
  transform: rotate(45deg);
}

.icon {
  width: 20px;
  height: 20px;
}

.icon-large {
  width: 25px;
  height: 25px;
}

.fat-icon {
  stroke-width: 4;
  width: 18px;
  height: 18px;
  transition: all 0.3s ease;
}

.shower,
.icon {
  cursor: pointer;
}

.race-shower {
  cursor: pointer;
}

.navbar-bg {
  background-color: transparent;
}

.navbar-brand {
  font-size: 2rem;
  padding-top: 0 !important;
}

.row {
  padding-bottom: 10px;
}

.table {
  font-size: 85%;
}

li .table {
  margin-bottom: 0;
}

li .race-detail {
  min-height: 300px;
}

li .race-detail .race-graph {
  min-height: 300px;
}

.table-dark.table-striped tbody tr.odd {
  background-color: #212529;
}

.table-dark {
  border: 1px solid #000;
  border-radius: 2px;
}

.table thead th {
  vertical-align: bottom;
  /*  border-bottom: 2px solid #212529;*/
}

.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: #fff;
}

.table .table {
  background-color: #212529 !important;
}

.table tbody td {
  border-top: 1px solid #2e2e2e;
  padding: 4px;
}

table[data-sortable].sortable-theme-dark th[data-sorted='true'] {
  background: #2e2e2e;
}

table[data-sortable] th:not([data-sortable='false']) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  cursor: pointer;
}

table[data-sortable] th:after {
  content: '';
  visibility: hidden;
  display: inline-block;
  vertical-align: inherit;
  height: 0;
  width: 0;
  border-width: 5px;
  border-style: solid;
  border-color: transparent;
  margin-right: 1px;
  margin-left: 10px;
  float: right;
}
/* line 40, ../sass/_sortable.sass */
table[data-sortable] th[data-sorted='true']:after {
  visibility: visible;
}
/* line 43, ../sass/_sortable.sass */
table[data-sortable] th[data-sorted-direction='descending']:after {
  border-top-color: inherit;
  margin-top: 8px;
}
/* line 47, ../sass/_sortable.sass */
table[data-sortable] th[data-sorted-direction='ascending']:after {
  border-bottom-color: inherit;
  margin-top: 3px;
}

.padded-link {
  margin-left: 1rem;
}

.m-m-t {
  margin-top: 1.5rem;
}

.list-group,
.list-group-item,
.list-group-item-dark {
  background-color: #333;
  color: #fff;
}

.hidden {
  visibility: hidden;
}

.invisible {
  display: none;
}

#hidden-graph-text {
  padding-top: 5px;
}

#compare-list svg {
  margin-right: 1rem;
  width: 20px;
  height: 20px;
}

.beta {
  text-transform: uppercase;
  font-size: 60%;
  transform: rotate(30deg);
  position: relative;
  top: -7px;
}

.text-image {
  padding-left: 1em;
  padding-right: 1em;
  margin-bottom: 0;
  vertical-align: top;
}

.right {
  float: right;
}

.left {
  float: left;
}

.byline {
  text-align: right;
}

.ingress {
  font-size: 115%;
  letter-spacing: 0.03em;
  line-height: 1.4em;
}

.ingress .byline {
  font-size: 80%;
}

#searchField {
  width: 17rem;
}

#compare-search-field {
  width: 25rem;
  margin-left: 1rem;
}

.dnf {
  text-transform: uppercase;
  background-color: white;
  color: #333;
  font-size: 85%;
  padding: 2px;
}

.stagerank,
.smaller {
  font-size: 80%;
}

.stagerank {
  position: relative;
  bottom: 1px;
  left: 1px;
  font-weight: 700;
  color: #ccc;
}

.stagetime {
  font-variant-numeric: tabular-nums;
}

.toplink {
  position: relative;
  top: 7px;
}

.middle-align {
  padding-top: 4px;
}

.btn {
  padding: 0.2rem 0.5rem;
}

a.btn {
  text-decoration: none !important;
}

.race-details {
  padding-top: 10px;
  padding-left: 10px;
}

.rider-chart {
  height: 300px;
}

.race-graph {
  height: 175px;
  width: 100%;
}

.race-prog-graph {
  height: 300px;
  width: 100%;
}

.race-detail-graph {
  height: 250px;
  width: 100%;
}

#map {
  height: 100%;
  min-height: 500px;
  padding-bottom: 5px;
}

#map a {
  color: #000 !important;
}
.map-chart-container {
  height: 300px;
}

#details {
  padding-top: 10px;
}

h4.detail {
  margin-top: 5px;
}

.details-row {
  padding-bottom: 5px;
}

#legend {
  margin-top: 15px;
}

table.legend tr {
  border: 1px solid #333;
}

table.legend td {
  text-align: center;
  padding: 5px;
  font-size: 85%;
}

table.counted {
  counter-reset: calendar-counter;
}

table.counted tbody tr {
  counter-increment: calendar-counter;
}

table.counted tbody tr:before {
  content: counter(calendar-counter);
}

.color {
  width: 70px;
  height: 20px;
}

.green1 {
  background-color: #29f23d;
}

.green2 {
  background-color: #1ec946;
}

.red1 {
  background-color: #f55c53;
}

.red2 {
  background-color: #f33a2f;
}

.red3 {
  background-color: #b5140b;
}

.red4 {
  background-color: #961109;
}
.red5 {
  background-color: #700d07;
}

.red6 {
  background-color: #570101;
}

.red7 {
  background-color: #440202;
}

.mlb {
  margin-left: 5px;
}

.frontpage-news {
  border: 1px solid #aaa;
  border-radius: 5px;
  padding: 10px 15px 10px 15px;
  background-color: #222;
  margin-top: 20px;
}

.frontpage-plug h1 a,
.frontpage-plug h2 a,
.frontpage-plug h3 a {
  border: none;
}

.frontpage-news h5 {
  color: #ccc;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
